import * as React from 'react';
import { IPopupCloseIconButtonProps } from '../PopupCloseIconButton.types';
import { TestIds } from '../constants';
import {
  activateBySpaceOrEnterButton,
  getAriaAttributes,
} from '../../../core/commons/a11y';
import { replaceCompIdPlaceholder } from '../../../core/commons/vectorImageUtils';
import style from './style/PopupCloseIconButton.scss';

const PopupCloseIconButton: React.FC<IPopupCloseIconButtonProps> = ({
  id,
  svgContent,
  onMouseEnter,
  onMouseLeave,
  onClick,
  onDblClick,
  ariaAttributes,
}) => (
  <div
    id={id}
    className={style.svgRoot}
    onKeyDown={activateBySpaceOrEnterButton}
    data-testid={TestIds.PopupCloseIconButtonRoot}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    onClick={onClick}
    onDoubleClick={onDblClick}
  >
    <div
      tabIndex={0}
      role="button"
      title="Back to site"
      aria-label="Back to site"
      {...getAriaAttributes(ariaAttributes)}
      className={style.svgButton}
      dangerouslySetInnerHTML={{
        __html: svgContent && replaceCompIdPlaceholder(svgContent, id),
      }}
    />
  </div>
);

export default PopupCloseIconButton;
